<template lang="pug">
div.img-cropper
  VueCropper(
    ref="newCropper"
    :aspect-ratio="3/4"
    :src="imgSrc"
    preview=".preview"
    :viewMode="1")
  div.btns-cropper.d-flex.flex-column
    div(v-for="icon in controlButtons" :key="icon.id")
      v-tooltip(left)
        template(#activator='{ on, attrs }')
          v-icon(@click="icon.action()" color="#FFFFFF" v-bind='attrs' v-on='on').pa-1 {{ icon.iconName }}
        span {{ $t(icon.tooltip) }}
  div(v-if="previewCrop").preview
    img(:src="previewCrop" width="100" height="130" alt="cropper-image")
</template>
<script>
import { blobToBase64 } from '@/mixins/main'
export default {
  components: {
    VueCropper: () => import('vue-cropperjs')
  },
  data () {
    return {
      cropImg: null,
      imgSrc: null,
      previewCrop: null,
      uploadFile: null,
      isEditedFileSaving: false,
      controlButtons: [
        { id: 1, iconName: 'mdi-flip-vertical', action: () => this.flipY(), tooltip: 'tooltip.flip-vertical' },
        { id: 2, iconName: 'mdi-flip-horizontal', action: () => this.flipX(), tooltip: 'tooltip.flip-horizontal' },
        { id: 3, iconName: 'mdi-rotate-left', action: () => this.rotate(-90), tooltip: 'tooltip.rotate-left' },
        { id: 4, iconName: 'mdi-rotate-right', action: () => this.rotate(90), tooltip: 'tooltip.rotate-righ' },
        { id: 5, iconName: 'mdi-crop-rotate', action: () => this.reset(), tooltip: 'tooltip.reset' },
        { id: 6, iconName: 'mdi-check', action: () => this.cropImage(), tooltip: 'tooltip.cropped' }
      ]
    }
  },
  methods: {
    setImage (file) {
      this.reset()
      this.uploadFile = file
      this.previewCrop = null
      this.cropImg = null
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = async (event) => {
          this.imgSrc = await event.target.result
          // rebuild cropperjs with the updated source
          setTimeout(() => {
            this.$refs.newCropper.replace(event.target.result)
          }, 200)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    async cropImage () {
      // get image data for post processing, e.g. upload or setting image src
      this.$refs.newCropper.getCroppedCanvas().toBlob(async blob => {
        this.previewCrop = await blobToBase64(blob)
        let type = this.uploadFile.type.split('/')[1]
        this.cropImg = new File([blob], `${this.uploadFile.name}.${type}`, { type: this.uploadFile.type })
        this.$emit('change', this.cropImg)
      }, this.uploadFile.type)
      // this.cropImg = blob
    },
    flipX () {
      const cropperData = this.$refs.newCropper.getData()
      this.$refs.newCropper.scaleX(cropperData.scaleX === -1 ? 1 : -1)
    },
    flipY () {
      const cropperData = this.$refs.newCropper.getData()
      this.$refs.newCropper.scaleY(cropperData.scaleY === -1 ? 1 : -1)
    },
    reset () {
      if (this.$refs.newCropper) this.$refs.newCropper.reset()
    },
    rotate (deg) {
      this.$refs.newCropper.rotate(deg)
    },
    clearCropper () {
      this.cropImg = null
      this.imgSrc = null
      this.previewCrop = null
      this.uploadFile = null
    }
  }
}
</script>

<style lang="sass" scoped>
.btns-cropper
  position: absolute
  top: 0
  left: -15px
  background: black
.preview
  position: absolute
  z-index: 9
  top: 230px
  left: 230px
  width: max-content
</style>>
